import React from 'react';
import {connect} from 'react-redux';
import {Switch, Route} from 'react-router-dom';
import 'antd/dist/antd.css';
import './App.css';
import PrivateRoute from './components/PrivateRoutes';
import Landingpage from './pages/Landingpage';
import LoadingBar from 'react-redux-loading-bar';
import Loginpage from './pages/Login';
import RegisterDomain from './pages/RegisterDomain';
import RegisteredSuccess from './pages/RegisteredSuccess';
import MyDomains from './pages/MyDomainsPage';
import SetupDomain from './pages/SetupDomain';
import PlanAndUsage from './pages/PlanAndUsage';
import Pro from './pages/Pro';
import ContactUs from './pages/contact/contactUs';
import Admin from './pages/Admin/Admin';

const App = () => {
  return (
    <div className="App">
      <LoadingBar style={{backgroundColor: '#037cff', height: '2px'}} />
      <Switch>
        <Route
          exact
          path="/"
          component={() => <PrivateRoute component={Landingpage} />}
        />
        <Route
          exact
          path="/login"
          component={() => <PrivateRoute component={Loginpage} />}
        />
        <Route
          exact
          path="/search"
          component={() => <PrivateRoute component={RegisterDomain} />}
        />
        <Route
          exact
          path="/pro"
          component={() => <PrivateRoute component={Pro} />}
        />
        <Route
          exact
          path="/search/success"
          component={() => <PrivateRoute component={RegisteredSuccess} />}
        />
        <Route
          exact
          path="/mydomains"
          component={() => <PrivateRoute component={MyDomains} />}
        />
        <Route
          exact
          path="/account/usage"
          component={() => <PrivateRoute component={PlanAndUsage} />}
        />
        <Route
          exact
          path="/setup/:domain"
          component={() => <PrivateRoute component={SetupDomain} />}
        />
        <Route exact path="/contactus" component={() => <ContactUs />} />
        <Route exact path="/admin" component={() => <Admin />} />
      </Switch>
    </div>
  );
};

export default connect()(App);
