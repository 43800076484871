export function track(event, data = {}) {
  if (window && window.mixpanel) {
    window.mixpanel.track(event, data);
  }
}

export function register(data = {}) {
  if (window) {
    window.mixpanel && window.mixpanel.register(data);
  }
}

export function flattenSession(session, channel) {
  return {
    Email: session.email,
    Fullname: session.fullname,
    SignUpChannel: channel,
    SignUpOn: session.createdon,
    CurrentPlan: session.limits.plan,
    UsageDomains: session.usage.allDomains,
    isLoggedIn: session.isLoggedIn,
  };
}
