import React from 'react';
import {Card, Divider} from 'antd';
import {PlusOutlined} from '@ant-design/icons';


const Features = () => {
  return (
    <>
          <Card
            style={{
              border: '2px solid #fe4365',
              minHeight: '300px',
              background: '#fe4365',
              color: '#ffffff',
            }}
          >
          <p style={{marginBottom: '20px', textAlign: 'center'}}>
              <span
                style={{
                  background: 'fd4264',
                  padding: '5px 10px',                  
                  borderRadius: '3px',
                  fontWeight: '900',
                  fontSize: '28px'
                }}
              >
                Features
              </span>
            </p>
            <p style={{marginBottom: '20px'}}>
              <span
                style={{
                  background: 'fd4264',
                  padding: '5px 10px',
                  border: '2px solid #ffffff',
                  borderRadius: '3px',
                  fontWeight: '600',
                }}
              >
                PRO Domain + Membership
              </span>
            </p>
            <p>
              <strong>Domain </strong>{' '}
              <strong
                style={{
                  borderBottom: '2px solid white',
                  padding: '4px 0px',
                }}
              >
                Never Expires
              </strong>
            </p>
            <p>
              Domain never expires untill you are in pro membership. No
              reactivation is necessory
            </p>
            <Divider>
              <PlusOutlined style={{color: '#ffffff'}} />
            </Divider>
            <p>
              Includes{' '}
              <span
                style={{
                  fontWeight: 700,
                  background: '#ffffff',
                  display: 'inline-block',
                  padding: '2px 5px',
                  color: '#fe4365',
                  borderRadius: '3px',
                }}
              >
                25 PRO Domains
              </span>
              <p style={{marginTop: '15px'}}>
                You can also delete and reuse the unwanted domains
              </p>
            </p>
          </Card>
    </>
  );
};
export default Features;
