import React from 'react';
import {Card} from 'antd';

import {LoadingOutlined} from '@ant-design/icons';

function Processing() {
  return (
    <>
      <Card
        style={{
          minHeight: '200px',
          background: '#ffffff',
          color: '#4b4b4b',
          textAlign: 'center',
        }}
      >
        <div style={{marginTop: '50px', marginBottom: '20px',color: '#fe4365'}}>
          <LoadingOutlined style={{fontSize: '40px'}} />
        </div>
        <div>
          <strong style={{fontSize: '24px', color: '#fe4365'}}>
            Upgrading Your Membership
          </strong>
        </div>
      </Card>
    </>
  );
}
export default Processing;
