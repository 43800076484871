import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import {loadingBarReducer} from 'react-redux-loading-bar';
import sessionReducer from './session';
import domainSearchReducer from './domainSearch';
import registerStatusReducer from './registerStatus';
import myDomainsReducer from './myDomains';
import domainSettingReducer from './domainSettings';
import userPlanReducer from './userPlan';

const createRootReducer = (history) => {
  return combineReducers({
    router: connectRouter(history),
    loadingBar: loadingBarReducer,
    sessionReducer,
    domainSearchReducer,
    registerStatusReducer,
    myDomainsReducer,
    domainSettingReducer,
    userPlanReducer,
  });
};
export default createRootReducer;
