import React, {Component} from 'react';
import s from './Faq.module.css';

class Faq extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    return (
      <div className={s['faq-block']}>
        <div className={s['faq-section']}>
          <div className={s['faq-header-section']}>
            <div className={s['faq-heading']}>Frequently Asked Questions</div>
            <div className={s['faq-subheading']}>
              Can't find an answer? Don't hesitate to reach out!.
            </div>
          </div>

          <div className={s['faq']}>
            <div className={s['faq-question']}>
              What is co.vu ? Is it a real domain registry?
            </div>
            <div className={s['faq-answer']}>
              co.vu is a free domain name service where you can register free
              domain names and dead simple way to connect to your popular online
              services like Tumblr, Github Pages, now.sh etc
            </div>
          </div>
          {false && (
            <div className={s['faq']}>
              <div className={s['faq-question']}>
                What can I use the co.vu domains for?
              </div>
              <div className={s['faq-answer']}>
                70% of the all the paid domains like .com are registered are
                never developed in to actual website. But many a time we just
                need a good domain to showcase our blog, resume, weekend project
                etc
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default Faq;
