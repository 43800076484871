import React from 'react';
import {useSelector} from 'react-redux';
import {isLoading, isSuccess} from '../../../utils/asyncStatusHelper';

import Payment from './payment';
import UpgradedPlan from './upgraded';
import UgradingPlan from './processing';

function PaymentOptions() {
  const {upgradePlanAsyncStatus, plan} = useSelector(
    ({sessionReducer, userPlanReducer}) => ({
      upgradePlanAsyncStatus: userPlanReducer.upgradePlanAsyncStatus,
      getUserPlanAsyncStatus: sessionReducer.sessionAsyncStatus,
      plan: sessionReducer.session.plan,
    })
  );

  return (
    <>
      {plan.membership !== 'ACTIVE' && !isLoading(upgradePlanAsyncStatus) && (
        <Payment></Payment>
      )}
      {isLoading(upgradePlanAsyncStatus) && <UgradingPlan></UgradingPlan>}
      {isSuccess(upgradePlanAsyncStatus) && <UpgradedPlan></UpgradedPlan>}
    </>
  );
}
export default PaymentOptions;
