/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Table, Tag, Skeleton, Space, Typography, Breadcrumb} from 'antd';

import {
  HomeOutlined,
  UpCircleOutlined,
  DownCircleOutlined,
} from '@ant-design/icons';
import {
  getDNSRecords,
  deleteDNSRecord,
  createDNSRecord,
  updateDNSRecord,
} from '../../actions/dnsCustomAction';
import PageHeader from '../../components/PageHeader';
import {isLoading} from '../../utils/asyncStatusHelper';
import ConfigureDomainForm from './configureDomainForm';
const {Text} = Typography;

const SetupDomain = ({
  match: {
    params: {domain},
  },
}) => {
  const domainInfo = {domain};
  const dispatch = useDispatch();
  const {dnsRecords, getDNSAsyncStatus} = useSelector(
    ({domainSettingReducer}) => {
      let dnsRecords =
        (domainSettingReducer.domains[domainInfo.domain] &&
          domainSettingReducer.domains[domainInfo.domain].dnsRecords) ||
        [];

      //dnsRecords = [{}, ...dnsRecords] //empty object is for AddNewDnsRecord FORM
      dnsRecords = dnsRecords[0] ? [dnsRecords[0]] : [{}];

      return {
        dnsRecords: dnsRecords,
        getDNSAsyncStatus: domainSettingReducer.getDNSAsyncStatus,
      };
    }
  );

  const handleSubmit = ({type, content, mode, recordId}) => {
    let postData = {domain: domainInfo.domain, type, content, recordId};
    if (mode === 'UPDATE') {
      dispatch(updateDNSRecord(postData));
    } else {
      dispatch(createDNSRecord(postData));
    }
  };

  const handleDelete = (id) => {
    dispatch(deleteDNSRecord(domainInfo.domain, id));
  };

  useEffect(() => {
    dispatch(getDNSRecords(domainInfo.domain));
  }, [dispatch, domainInfo.domain]);

  const columns = [
    {
      dataIndex: 'type',
      key: 'type',
      width: '20%',
      render: (type) => (
        <>
          <Text type="secondary" style={{marginRight: '10px'}}>
            Type
          </Text>
          <Tag>{type}</Tag>
        </>
      ),
    },
    {
      dataIndex: 'content',
      key: 'content',
      width: '63%',
      render: (content) => (
        <>
          <Text type="secondary" style={{marginRight: '10px'}}>
            Content
          </Text>{' '}
          <strong>{content}</strong>
        </>
      ),
    },
  ];

  return (
    <>
      <PageHeader />

      <div style={{width: '1100px', margin: 'auto'}}>
        <div style={{padding:'10px', margin:'20px 0px', background:'#ffffff'}}>
          <Breadcrumb>
            <Breadcrumb.Item href="/mydomains">
              <HomeOutlined />
            </Breadcrumb.Item>
            <Breadcrumb.Item href="/mydomains">
              <span>{domain}.co.vu</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Setup</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div style={{background: '#ffffff', padding: '25px 25px 0px 25px'}}>
            <div
            style={{
                textAlign: 'center',
                fontSize: '18px',
                background: '#fbfbfb',
                padding: '20px'
            }}            
            >Configure DNS Settings</div> 
        </div>
        
        {(isLoading(getDNSAsyncStatus) && (
          <div style={{background: '#ffffff', padding: '10px'}}>
            {[1].map((item) => (
              <Space
                key={item}
                style={{margin: '5px', marginTop: item !== 1 ? '20px' : '5px'}}
              >
                <Skeleton.Input style={{width: '75px'}} active={true} />
                <Skeleton.Input
                  style={{width: '575px', marginLeft: '25px'}}
                  active={true}
                />
                <Skeleton.Input
                  style={{width: '150px', marginLeft: '25px'}}
                  active={true}
                />
              </Space>
            ))}
          </div>
        )) ||
          (getDNSAsyncStatus === 'SUCCESS' &&
            dnsRecords &&
            dnsRecords[0].recordId !== undefined && (
              <Table
                pagination={false}
                showHeader={false}
                columns={columns}
                expandable={{
                  expandedRowRender: (dnsRecord) => {
                    return (
                      <ConfigureDomainForm
                        insideExpandable={true}
                        dnsRecord={dnsRecord}
                        onSubmit={handleSubmit}
                        onDelete={handleDelete}
                      />
                    );
                  },
                  rowExpandable: (record) => true,
                  expandIconColumnIndex: 3,
                  expandIcon: ({expanded, onExpand, record}) => {
                    return expanded ? (
                      <div style={{width: '90px'}}>
                        <a onClick={(e) => onExpand(record, e)}>
                          configure <UpCircleOutlined />
                        </a>
                      </div>
                    ) : (
                      <div style={{width: '90px'}}>
                        <a onClick={(e) => onExpand(record, e)}>
                          configure <DownCircleOutlined />
                        </a>
                      </div>
                    );
                  },
                }}
                dataSource={
                  getDNSAsyncStatus === 'SUCCESS' &&
                  dnsRecords &&
                  dnsRecords[0].recordId !== undefined
                    ? dnsRecords.map((record, index) => ({
                        key: index,
                        ...record,
                      }))
                    : []
                }
              />
            )) ||
          (getDNSAsyncStatus === 'SUCCESS' && (
            <ConfigureDomainForm
              dnsRecord={{}}
              onSubmit={handleSubmit}
              onDelete={handleDelete}
            />
          ))}
      </div>
    </>
  );
};
export default SetupDomain;
