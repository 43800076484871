/* eslint-disable react/jsx-no-duplicate-props */
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Row, Col, Menu} from 'antd';
// import {getUserPlan} from '../../actions/planAction';
import {getSession} from '../../actions/authAction';
import PageHeader from '../../components/PageHeader';
import PlanDetails from './planDetails';

const PlanAndUsage = () => {
  const dispatch = useDispatch();
  const {
    // eslint-disable-next-line no-unused-vars
    domainSearchReducer: {domain, availability},
  } = useSelector(({domainSearchReducer}) => ({
    domainSearchReducer,
  }));

  useEffect(() => {
    dispatch(getSession());
  }, [dispatch]);

  return (
    <>
      <PageHeader />

      <div
        style={{
          margin: '30px auto',
          width: '1100px',
        }}
      >
        <h2
          style={{marginTop: '30px', marginBottom: '30px', marginLeft: '5px'}}
        >
          Usage & Plan
        </h2>
      </div>
      <div
        style={{
          margin: '30px auto',
          padding: '10px',
          background: '#ffffff',
          width: '1100px',
        }}
      >
        <Row gutter={16}>
          <Col span={6}>
            <Menu
              onClick={() => {}}
              style={{width: 256}}
              mode="inline"
              style={{minHeight: '600px'}}
            >
              <Menu.Item key="1">Usage & Plan</Menu.Item>
              <Menu.Item key="2">Account Settings</Menu.Item>
            </Menu>
          </Col>
          <Col span={16}>
            <PlanDetails />
          </Col>
        </Row>
      </div>
    </>
  );
};
export default PlanAndUsage;
