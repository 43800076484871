import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {upgradePlan} from '../../actions/planAction';
import {Card} from 'antd';
import PayPalBtn from '../../components/PaypalButton';
import mixpanel from '../../utils/mixpanel';

function Payment() {
  const dispatch = useDispatch();
  const {
    session: {
      id,
      email,
      paymentOptions: {
        key: clientId,
        plan: {id: planId, amount: planAmount},
      },
    },
  } = useSelector(({sessionReducer}) => ({
    session: sessionReducer.session,
  }));

  return (
    <>
      <Card
        style={{
          minHeight: '200px',
          background: '#ffffff',
          color: '#4b4b4b',
          textAlign: 'center',
        }}
      >
        <div style={{marginTop: '50px'}}>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            Upgrade to PRO Membership
          </div>
        </div>
        <div>
          <strong style={{fontSize: '39px', color: '#fe4365'}}>
            ${planAmount} / month
          </strong>
        </div>
        <div
          style={{
            padding: '10px 48px',
          }}
        >
          <PayPalBtn
            amount={planAmount}
            currency="USD"
            clientId={clientId}
            createSubscription={(data, actions) => {
              mixpanel.track('Pro Init', {location: 'ProPage'});
              return actions.subscription.create({
                plan_id: planId,
                custom_id: JSON.stringify({id, email}),
              });
            }}
            onApprove={(data, detail) => {
              mixpanel.track('Pro Upgrade', {location: 'ProPage'});
              dispatch(upgradePlan(data.subscriptionID));
              console.log(data.subscriptionID, data, detail);
            }}
            catchError={(err) => {
              mixpanel.track('Pro Paypal Error', {location: 'ProPage'});
              console.log('Paypal CatchError', err);
            }}
            onError={(err) => {
              mixpanel.track('Pro Error', {location: 'ProPage'});
              console.log('Error', err);
            }}
            onCancel={(err) => {
              mixpanel.track('Pro Cancel', {location: 'ProPage'});
              console.log('Error', err);
            }}
          />
        </div>
        <div>Cancel Anytime</div>
      </Card>
    </>
  );
}
export default Payment;
