import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Tag} from 'antd';
import {Link} from 'react-router-dom';

import moment from 'moment';
import {isLoading, isSuccess} from '../../utils/asyncStatusHelper';

const PlanDetails = () => {
  const dispatch = useDispatch();
  const {getUserPlanAsyncStatus, downgradePlanAsyncStatus, plan} = useSelector(
    ({sessionReducer, userPlanReducer}) => ({
      downgradePlanAsyncStatus: userPlanReducer.downgradePlanAsyncStatus,
      getUserPlanAsyncStatus: sessionReducer.sessionAsyncStatus,
      plan: sessionReducer.session.plan,
    })
  );

  return (
    <>
      <p style={{margin: '20px'}}>
        <strong>User Plan</strong>
      </p>
      <div style={{textAlign: 'center'}}>
        {plan.isPro && (
          <div style={{margin: '20px', padding: '20px', background: '#f1f1f1'}}>
            <p>
              Your in <Tag color="#fe4365">PRO</Tag> Member with{' '}
              {plan.limits.domain} PRO Domains
            </p>
            {plan.membership === 'ACTIVE' ? (
              <>
                <Button
                  type="link"
                  disabled={isLoading(downgradePlanAsyncStatus)}
                  loading={isLoading(downgradePlanAsyncStatus)}
                  onClick={() => {
                    // eslint-disable-next-line no-undef
                    dispatch(downgradePlan());
                  }}
                >
                  {' '}
                  Cancel Membership{' '}
                </Button>
              </>
            ) : (
              <>
                <p>
                  Your PRO Membership will end on{' '}
                  <strong>
                    {moment(plan.planActiveTill).format('MMMM Do YYYY')}.
                  </strong>
                </p>
                <Link to={`/pro`}>
                  <Button
                    style={{background: '#fe4365', border: '#fe4365'}}
                    type="primary"
                  >
                    Reactivate Membership
                  </Button>
                </Link>
                <p>
                  Reactivate membership before{' '}
                  {moment(plan.planActiveTill).format('MMM Do YYYY')} to stop
                  you domain from expiring
                </p>
              </>
            )}
          </div>
        )}

        {isSuccess(getUserPlanAsyncStatus) && !plan.isPro && (
          <div style={{margin: '20px', padding: '20px', background: '#f1f1f1'}}>
            <p>Your in Basic Member with {plan.limits.domain} FREE Domains</p>
            <Link to={`/pro`}>
              <Button
                style={{background: '#fe4365', border: '#fe4365'}}
                type="primary"
              >
                Reactivate Membership
              </Button>
            </Link>
          </div>
        )}
      </div>
    </>
  );
};
export default PlanDetails;
