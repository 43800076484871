import React from 'react';
import {useSelector} from 'react-redux';
import PageHeader from '../../components/PageHeader';
import Free from './Free';
import FreeEmpty from './freeEmpty';
import RegisterProDomain from './registerProDomain';
import Search from './search';

const RegisterDomain = () => {
  const {
    domainSearchReducer: {domain, availability},
  } = useSelector(({domainSearchReducer}) => ({
    domainSearchReducer,
  }));

  return (
    <>
      <PageHeader />
      <div style={{textAlign: 'center'}}>
        <Search />
        {domain && availability && (
          <>
            <RegisterProDomain domain={domain} />
            {/* <Free></Free> */}
            <FreeEmpty></FreeEmpty>
          </>
        )}
      </div>
    </>
  );
};
export default RegisterDomain;
