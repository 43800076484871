import axios from 'axios';
import {showLoading, hideLoading} from 'react-redux-loading-bar';
import {SESSION_SUCCESS} from './authAction';
import mixpanel from '../utils/mixpanel';

export const GET_MYDOMAINS_REQUEST = 'GET_MYDOMAINS_REQUEST';
export const GET_MYDOMAINS_SUCCESS = 'GET_MYDOMAINS_SUCCESS';
export const GET_MYDOMAINS_ERROR = 'GET_MYDOMAINS_ERROR';

export const DELETE_DOMAIN_REQUEST = 'DELETE_DOMAIN_REQUEST';
export const DELETE_DOMAIN_SUCCESS = 'DELETE_DOMAIN_SUCCESS';
export const DELETE_DOMAIN_ERROR = 'DELETE_DOMAIN_ERROR';

export const OPEN_RENEW_POPUP = 'OPEN_RENEW_POPUP';
export const CLOSE_RENEW_POPUP = 'CLOSE_RENEW_POPUP';

export const RENEW_DOMAIN_REQUEST = 'RENEW_DOMAIN_REQUEST';
export const RENEW_DOMAIN_SUCCESS = 'RENEW_DOMAIN_SUCCESS';
export const RENEW_DOMAIN_ERROR = 'RENEW_DOMAIN_ERROR';

export const getMyDomains = () => async (dispatch) => {
  dispatch({type: GET_MYDOMAINS_REQUEST});
  dispatch(showLoading());
  try {
    const {
      data: {data},
    } = await axios.get('/api/v2/user/domains');
    dispatch(hideLoading());
    dispatch({type: GET_MYDOMAINS_SUCCESS, payload: data});
  } catch (error) {
    dispatch(hideLoading());
    dispatch({type: GET_MYDOMAINS_ERROR, payload: error.data});
  }
};

export const deleteDomain = (domain) => async (dispatch) => {
  dispatch({type: DELETE_DOMAIN_REQUEST});
  dispatch(showLoading());
  try {
    mixpanel.track("Delete Domain");
    const {
      data: {session},
    } = await axios.post('/api/v2/user/domains/delete', {
      domain,
    });
    dispatch(hideLoading());
    dispatch({type: SESSION_SUCCESS, payload: session});
    dispatch(getMyDomains());
  } catch (error) {
    dispatch(hideLoading());
    dispatch({type: DELETE_DOMAIN_ERROR, payload: error.data});
  }
};

export const renewDomain = (domain) => async (dispatch) => {
  dispatch({type: RENEW_DOMAIN_REQUEST});
  dispatch(showLoading());
  try {
    mixpanel.track("Renew Domain");
    const {data: payload} = await axios.post('/api/v2/user/domains/renew', {
      domain,
    });
    dispatch(hideLoading());
    dispatch({type: RENEW_DOMAIN_SUCCESS, payload});
    dispatch(getMyDomains());
  } catch (error) {
    dispatch(hideLoading());
    dispatch({type: RENEW_DOMAIN_ERROR, payload: error.data});
  }
};

export const openRenewDomainPopup = (domainDetails) => (dispatch) => {
  dispatch({type: OPEN_RENEW_POPUP, payload: domainDetails});
};

export const closeRenewDomainPopup = () => (dispatch) => {
  dispatch({type: CLOSE_RENEW_POPUP});
};
