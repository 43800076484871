import {INIT, SUCCESS} from '../utils/asyncStatusHelper';
import {SESSION_SUCCESS, LOGIN_SUCCESS, LOGOUT_SUCCESS} from '../actions/authAction';

const initialState = {
  sessionAsyncStatus: INIT,
  loginAsyncStatus: INIT,
  loggedIn: false,
  session: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, {type, payload}) => {
  switch (type) {
    case SESSION_SUCCESS:
      return {
        ...state,
        loggedIn: payload.isLoggedIn,
        session: payload,
        sessionAsyncStatus: SUCCESS,
        loginAsyncStatus: SUCCESS,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        session: payload,
        sessionAsyncStatus: SUCCESS,
        loginAsyncStatus: SUCCESS,
      };
      case LOGOUT_SUCCESS:
        return {
          ...state,
          loggedIn: false,
          session: null,
          sessionAsyncStatus: SUCCESS,
          loginAsyncStatus: SUCCESS,
        };
      
    default:
      return state;
  }
};
