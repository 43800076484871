/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
// import {useDispatch} from 'react-redux';
import {Link, NavLink} from 'react-router-dom';
import {Row, Col, Space} from 'antd';
import logoImg from '../../images/logo.svg';
import styles from './styles.module.css';


const UnauthorizedPageHeader = () => {
  // const dispatch = useDispatch();
  return (
    <>
      <Row className={styles["row"]} >
        <Col flex="600px" className={styles["col"]} >
          <Link to="/">
            <img src={logoImg} style={{width: '90px'}} />
          </Link>
        </Col>
        
        <Col flex="auto" className={styles["col-login"]}>
          <Space>
            <NavLink
              to="/contactus"
              className={styles["nav-link"]}
            >
              Contact Us
            </NavLink>
            <NavLink
              to="/login"
              className={styles["nav-link"]}
            >
              Login
            </NavLink>
          </Space>
        </Col>
      </Row>
    </>
  );
};

export default UnauthorizedPageHeader;
