import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import UnauthorizedPageHeader from '../../components/PageHeader/UnauthorizedPageHeader';
import {Form, Button, Input} from 'antd';
import axios from 'axios';

const layout = {
  labelCol: {span: 6},
  wrapperCol: {span: 16},
};
const tailLayout = {
  wrapperCol: {offset: 6, span: 16},
};

const Admin = () => {
  const [domainForDel, setDomainForDel] = useState('');
  const [domainDetails, setDomainDetails] = useState('');
  const dispatch = useDispatch();
  const onFinish = async (values) => {
    console.log(values);

    const {domain, url} = values;

    let tmpDomain;

    if (domain && domain.trim() !== '') {
      tmpDomain = domain.trim().replace('.co.vu', '');
    } else {
      tmpDomain = url.replace('hxxp', 'http');
      tmpDomain = tmpDomain.replace('[://]', '://');
      tmpDomain = tmpDomain.replaceAll('[.]', '.');
      tmpDomain = new URL(tmpDomain).hostname.replace('.co.vu', '');
    }
    setDomainForDel(tmpDomain);
    try {
      const {data: data} = await axios.get(
        `/api/admin/user?domain=${tmpDomain}`
      );
      setDomainDetails(data);
    } catch (e) {
      setDomainDetails(e.response.data);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <div style={{textAlign: 'center'}}>
        <div
          style={{
            margin: 'auto',
            background: '#ffffff',
            padding: '20px',
          }}
        >
          <h1>Admin</h1>
          <Form
            {...layout}
            name="basic"
            size="large"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item label="Domain" name="domain">
              <Input />
            </Form.Item>

            <Form.Item label="Url" name="url">
              <Input />
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                //loading={isLoading(loginAsyncStatus)}
              >
                Search
              </Button>
            </Form.Item>
          </Form>

          <strong>{domainForDel}.co.vu</strong>

          <div>
            {' '}
            <Button
              size="large"
              type="primary"
              onClick={async () => {
                try {
                  const {data: data} = await axios.get(
                    `/api/admin/takedown?domain=${domainForDel}`
                  );
                  setDomainDetails(data);
                } catch (e) {
                  setDomainDetails(e.response.data);
                }
              }}
            >
              Takedown
            </Button>
          </div>
          <hr />

          <pre style={{textAlign: 'left'}}>
            {JSON.stringify(domainDetails, null, 2)}
          </pre>
        </div>
      </div>
    </>
  );
};

export default Admin;
