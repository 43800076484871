/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, NavLink} from 'react-router-dom';
import {
  UserOutlined,
  SearchOutlined,
  UnorderedListOutlined,
  ThunderboltFilled,
} from '@ant-design/icons';



import {Dropdown, Row, Col, Space, Avatar, Menu} from 'antd';
import logoImg from '../../images/logo.svg';
import {logout} from '../../actions/authAction';

const PageHeader = () => {
  const dispatch = useDispatch();
  const {
    session,
  } = useSelector(({sessionReducer}) => ({
    session: sessionReducer.session,
  }));

  return (
    <>
      <Row
        style={{
          padding: '15px 30px 15px 30px',
          borderBottom: '1px solid #e6e6e6',
          background: ' #ffffff',
        }}
      >
        <Col
          flex="600px"
          style={{
            color: '#ffffff',
            flex: '0 0 450px',
            lineHeight: '33px',
            fontSize: '15px',
            fontWeight: '600',
          }}
        >
          <Link to="/">
            <img src={logoImg} style={{width: '90px'}} />
          </Link>
          <NavLink
            to="/search"
            style={{
              padding: '22px',
              // background: '#eaf4fd',
              // borderBottom: '3px solid #077cfe',
            }}
            activeStyle={{
              fontWeight: 'bold',
              color: '#037cff',
              borderBottom: '2px #1a90ff solid',
            }}
          >
            <SearchOutlined />
            <span
              style={{
                fontSize: '16px',
                color: '#037cff',
                fontWeight: '500',
                padding: '20px 10px',
              }}
            >
              Get a new domain
            </span>
          </NavLink>
          {/* <Input
            allowClear
            style={{width: '300px'}}
            placeholder="Get a new domain"
            prefix={<SearchOutlined />}
            size="large"
            addonAfter=".co.vu"
          /> */}
        </Col>
        <Col flex="auto" style={{textAlign: 'right', fontSize: '20px'}}>
          <Space>
          {
            !session?.plan?.isPro && (<NavLink
              to="/pro"
              icon={<UnorderedListOutlined />}
              style={{
                fontSize: '16px',
                color: '#fe4365',
                fontWeight: 500,
                lineHeight: '16px',
                padding: '20px 10px',
              }}
              activeStyle={{
                fontWeight: 'bold',
                color: '#fe4365',
                borderBottom: '2px #fe4365 solid',
              }}
            >
              <ThunderboltFilled /> Go Pro
            </NavLink>)
          }
            <NavLink
              to="/mydomains"
              icon={<UnorderedListOutlined />}
              style={{
                fontSize: '16px',
                color: '#5f6368',
                fontWeight: 500,
                lineHeight: '16px',
                padding: '20px 10px',
              }}
              activeStyle={{
                fontWeight: 'bold',
                color: '#037cff',
                borderBottom: '2px #1a90ff solid',
              }}
            >
              <UnorderedListOutlined /> My Domains
            </NavLink>
            {/*
            < NavLink
              to="/watchlist"
              style={{
                fontSize: '16px',
                // color: '#037cff',
                // fontWeight: '700',
                padding: '20px 10px',
              }}
              activeStyle={{
                fontWeight: 'bold',
                color: '#037cff',
                borderBottom: '2px #1a90ff solid',
              }}
            >
              <Badge count={5} size="small" style={{background: '#1a73e8'}}>
                <HeartOutlined style={{fontSize: '19px', color: '#607D8B'}} />
              </Badge>
            </NavLink>
            */}
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item key="0" to="/account/usage">
                    <Link to="/account/usage">Settings & Plan</Link>
                  </Menu.Item>

                  <Menu.Divider />
                  <Menu.Item
                    key="1"
                    onClick={() => {
                      dispatch(logout());
                    }}
                  >
                    Logout
                  </Menu.Item>
                </Menu>
              }
            >
              <Avatar
                size="default"
                style={{backgroundColor: '#FFFFFF'}}
                icon={<UserOutlined style={{color: '#607D8B'}} />}
              />
            </Dropdown>
          </Space>
        </Col>
      </Row>
    </>
  );
};

export default PageHeader;
