import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Form, Button, Input, Typography} from 'antd';
import {
  SearchOutlined,
  CheckCircleTwoTone,
  StopTwoTone,
} from '@ant-design/icons';
import {
  searchDomain,
  searchDomainResetAction,
} from '../../actions/searchDomainAction';

const Title = Typography.Title;

const Search = () => {
  const dispatch = useDispatch();
  const {
    domainSearchReducer: {domain, availability},
  } = useSelector(({domainSearchReducer}) => ({
    domainSearchReducer,
  }));

  useEffect(() => {
    return function () {
      dispatch(searchDomainResetAction());
    };
  }, [dispatch]);

  const onFinish = (values) => {
    dispatch(searchDomain(values));
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <Title
        level={3}
        textAlign="center"
        style={{marginTop: '80px', marginBottom: '35px'}}
      >
        Search Domain or Keyword or Name
      </Title>
      <div
        style={{
          width: '700px',
          margin: domain ? '40px auto 20px auto' : '40px auto 20px auto',
        }}
      >
        <Form
          wrapperCol={{span: 24}}
          name="basic"
          size="large"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={(values) => {
            console.log(values);
          }}
        >
          <Form.Item name="domain">
            <Input
              bordered={false}
              prefix={<SearchOutlined style={{marginRight: '10px'}} />}
              placeholder="Get a new domain"
              suffix={<span style={{fontWeight: 'bold'}}>.co.vu</span>}
              // suffix={<img src={logoImg} style={{width: '80px'}}></img>}
              style={{
                padding: '15px 20px',
                fontSize: '20px',
                background: '#fdfdfd',
                // border: '2px solid lightgrey',
                boxShadow: '1px 1px 6px 0px #3333335e',
                borderRadius: '5px',
              }}
            />
          </Form.Item>

          <Form.Item wrapperCol={{span: 24}} hidden>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              //loading={isLoading(loginAsyncStatus)}
            >
              Search Domain
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div style={{visibility: domain ? true : false}}>
        {domain && (
          <>
            <div style={{fontSize: '24px'}}>
              {availability ? (
                <>
                  <CheckCircleTwoTone twoToneColor="#077cfe" />
                  <strong
                    style={{
                      lineHeight: '55px',
                      padding: '10px',
                      color: '#262626',
                    }}
                  >
                    {domain}.co.vu
                  </strong>
                  is available for registration
                </>
              ) : (
                <>
                  <StopTwoTone twoToneColor="#077cfe" />
                  <strong
                    style={{
                      lineHeight: '55px',
                      padding: '10px',
                      color: '#262626',
                    }}
                  >
                    {domain}.co.vu
                  </strong>
                  is already in use
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default Search;
