import React from 'react';
import {useSelector} from 'react-redux';
import {Result, Button, Tag} from 'antd';
import {Link} from 'react-router-dom';

const ProSuccess = () => {
  const {registerStatus} = useSelector(({registerStatusReducer}) => ({
    registerStatus: registerStatusReducer.registerStatus,
  }));
  return (
    <>
      <Result
        style={{background: 'white'}}
        status="success"
        title={
          <>
            <strong
              style={{
                paddingRight: '7px',
                background: '#f2f2f2',
                padding: '5px 15px',
                marginRight: '6px',
                borderRadius: '4px',
              }}
            >
              {registerStatus.domain}.co.vu
            </strong>
            domain registered successfully
          </>
        }
        subTitle={
          <div style={{width: '900px', margin: '10px auto'}}>
            <Tag color="#fe4365">PRO Domain</Tag>
          </div>
        }
        extra={[
          <Link to="/search" key="search">
            <Button size="large">Back To Search</Button>
          </Link>,
          <Link to="/" key="dnssetup">
            <Button type="primary" size="large">
              Manage DNS
            </Button>
          </Link>,
        ]}
      />
    </>
  );
};
export default ProSuccess;
