import React from 'react';
import { Form, Input, Select, Row, Col, Button } from 'antd';
import {DeleteOutlined} from '@ant-design/icons';
const { Option } = Select;

const ConfigureDomainForm = ({dnsRecord,onSubmit,onDelete,insideExpandable})=>{
    const [form] = Form.useForm();
    const onFinish = (values)=>{
        values = {
            mode:(dnsRecord.recordId!==undefined)?"UPDATE":"ADD",
            recordId:(dnsRecord.recordId!==undefined)?dnsRecord.recordId:undefined,
            ...values
        }
        
        onSubmit(values);
    }

    const getFormStyle=(_insideExpandable)=>{
        if(_insideExpandable){
            return {}
        }else{
            //TODO move to module.css and use className instead
            return {
                width:"100%",
                padding:"25px", 
                paddingBottom: "5px", 
                backgroundColor:"#fff"
            }
        }
    }

    const getContentFieldAttributes= (type)=>{
        const contentFieldAttrMap = {
            "A":{
                validationPattern:/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
                ,validationMsg:"Please enter a valid ip address"
                ,placeholder:"IP Address"
            }
            ,"CNAME":{
                validationPattern:/^([A-Za-z0-9-]{1,63}\.)+[A-Za-z]{2,6}$/
                ,validationMsg:"Please enter a valid domain address"
                ,placeholder:"Host Name"
            }
        }
        return contentFieldAttrMap[type];
    }

    const resetContentField=()=>{
        form.setFieldsValue({content:""})
    }

    return (
        <Form 
            form={form}
            colon={false} 
            style={getFormStyle(insideExpandable)} 
            onFinish={onFinish}
            initialValues={{type: (dnsRecord.type || 'CNAME'),
                            content: (dnsRecord.content || null)}}
            >        
            <Row>
                <Col span={5}>    
                    <Form.Item name={"type"} label={"Type"} rules={[{required: true}]}  >
                        <Select style={{"width":"110px"}} placeholder="Type"  onChange={resetContentField}>
                            <Option value="A">A</Option>
                            <Option value="CNAME">CNAME</Option>
                        </Select>
                    </Form.Item>
                </Col>
                
                <Col span={16}>
                    <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.type !== curValues.type}>
                        {({getFieldValue}) => {
                            const contentFieldAttr  = getContentFieldAttributes(getFieldValue("type"));
                            return (
                                <Form.Item
                                    name="content"
                                    label="content"
                                    rules={[
                                        {
                                        required: true,
                                        pattern: contentFieldAttr.validationPattern,
                                        message: contentFieldAttr.validationMsg,
                                        }
                                    ]}>
                                    <Input placeholder={contentFieldAttr.placeholder} style={{"minWidth":"250px","width":"90%"}} />
                                </Form.Item>
                            )
                        }}
                    </Form.Item> 

                </Col>
                
                <Col span={2}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            {(dnsRecord.recordId!==undefined)?'Edit':'Add'}
                        </Button>
                    </Form.Item>
                </Col>
                <Col span={1} >
                    {(dnsRecord.recordId!==undefined)?
                        <DeleteOutlined onClick={()=>onDelete(dnsRecord.recordId)} style={{"marginLeft":"20px","marginTop":"7px"}}/>
                        :''
                    }
                </Col>
                
            </Row>
        </Form>
        
    )
}
export default ConfigureDomainForm;
