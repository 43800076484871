import {
  SEARCH_DOMAIN_START,
  SEARCH_DOMAIN_REQUEST,
  SEARCH_DOMAIN_SUCCESS,
  SEARCH_DOMAIN_RESET,
  REGISTER_DOMAIN_REQUEST,
  REGISTER_DOMAIN_SUCCESS
} from '../actions/searchDomainAction';
import {INIT, LOADING, SUCCESS} from '../utils/asyncStatusHelper';

const initialState = {
  asyncStatus: INIT,
  registerAsyncStatus: INIT,
  registerStatus: null,
};

export default function domainSearch(state = initialState, {type, payload}) {
  switch (type) {
    case SEARCH_DOMAIN_START:
    case SEARCH_DOMAIN_REQUEST:
      return {
        ...state,
        ...payload,
        asyncStatus: LOADING,
      };
    case SEARCH_DOMAIN_SUCCESS:
      return {
        ...state,
        ...payload,
        asyncStatus: SUCCESS,
      };
    case SEARCH_DOMAIN_RESET:
      return initialState;
    case REGISTER_DOMAIN_REQUEST:
      return {
        ...state,
        ...payload,
        registerAsyncStatus: LOADING,
      };
    case REGISTER_DOMAIN_SUCCESS:
      return {
        ...state,
        registerStatus: payload,
        registerAsyncStatus: SUCCESS,
      };
    default:
      return state;
  }
}
