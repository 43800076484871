/* eslint-disable no-unused-vars */
import React from 'react';
import {useDispatch} from 'react-redux';
import {Button, Typography} from 'antd';
import {loginAction, loginGmail} from '../../actions/authAction';
// import PageHeader from '../../components/PageHeader';
// import {isLoading} from '../../utils/asyncStatusHelper';
import GoogleLogin from 'react-google-login';
import {GoogleOutlined} from '@ant-design/icons';
import UnauthorizedPageHeader from '../../components/PageHeader/UnauthorizedPageHeader';
const {Title} = Typography;

const layout = {
  labelCol: {span: 6},
  wrapperCol: {span: 16},
};
const tailLayout = {
  wrapperCol: {offset: 6, span: 16},
};

const Login = () => {
  const dispatch = useDispatch();
  // const {sessionReducer} = useSelector(({sessionReducer}) => ({
  //   sessionReducer,
  // }));

  // // useEffect(() => {
  // //   dispatch(getSession());
  // // }, [dispatch]);

  const onFinish = (values) => {
    console.log(values);
    dispatch(loginAction(values));
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  //const {loginAsyncStatus} = sessionReducer;

  return (
    <>
      <UnauthorizedPageHeader />
      <div style={{textAlign: 'center'}}>
        <div
          style={{
            width: '400px',
            margin: 'auto',
            background: '#ffffff',
            padding: '20px',
            marginTop: '80px',
          }}
        >
          <Title level={3} textAlign="center" style={{marginBottom: '35px'}}>
            Login In
          </Title>
          <div style={{padding: '10px'}}>
            <GoogleLogin
              clientId="183094191766-io6rl63td2o2a879olmf6gp46bvqul98.apps.googleusercontent.com"
              buttonText="Signup With Google"
              theme="dark"
              accessType="offline"
              scope="openid profile email"
              render={(renderProps) => (
                <Button
                  type="primary"
                  size="large"
                  icon={<GoogleOutlined />}
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                >
                  Login with Google
                </Button>
              )}
              onSuccess={(data) => {
                dispatch(loginGmail(data.tokenId));
                console.log(data);
              }}
              onFailure={(data) => {
                console.log(data);
              }}
            ></GoogleLogin>
          </div>

          {/* <Form
            {...layout}
            name="basic"
            size="large"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[{required: true, message: 'Please input your email!'}]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[{required: true, message: 'Please input your password!'}]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                //loading={isLoading(loginAsyncStatus)}
              >
                Login
              </Button>
            </Form.Item>
          </Form> */}
        </div>
      </div>
    </>
  );
};
export default Login;
