import React from 'react';
import {LoadingOutlined} from '@ant-design/icons';
import s from './style.module.css';

const PageLoader = () => {
  return (
    <div className={s['loader']}>
      <LoadingOutlined style={{fontSize: '40px'}} />
    </div>
  );
};

export default PageLoader;
