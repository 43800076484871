import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {upgradePlan} from '../../../actions/planAction';
import PayPalBtn from '../../../components/PaypalButton';
import mixpanel from '../../../utils/mixpanel';

function Payment() {
  const dispatch = useDispatch();
  const {
    session: {
      id,
      email,
      paymentOptions: {
        key: clientId,
        plan: {id: planId, amount: planAmount},
      },
    },
  } = useSelector(({sessionReducer}) => ({
    session: sessionReducer.session,
  }));

  return (
    <>
      <div style={{marginTop: '50px'}}>
        <div
          style={{
            textAlign: 'center',
          }}
        >
          Upgrade to PRO Membership
        </div>
      </div>
      <div>
        <strong style={{fontSize: '30px', color: '#fe4365'}}>
          ${planAmount} / month
        </strong>
      </div>
      <div
        style={{
          padding: '10px 48px',
        }}
      >
        <PayPalBtn
          amount={planAmount}
          currency="USD"
          clientId={clientId}
          createSubscription={(data, actions) => {
            mixpanel.track('Pro Init', {location: 'Register'});
            return actions.subscription.create({
              plan_id: planId,
              custom_id: JSON.stringify({id, email}),
            });
          }}
          onApprove={(data, detail) => {
            mixpanel.track('Pro Upgrade', {location: 'Register'});
            dispatch(upgradePlan(data.subscriptionID));
            console.log(data.subscriptionID, data, detail);
          }}
          catchError={(err) => {
            mixpanel.track('Pro Paypal Error');
            console.log('Paypal CatchError', err);
          }}
          onError={(err) => {
            mixpanel.track('Pro Error');
            console.log('Error', err);
          }}
          onCancel={(err) => {
            mixpanel.track('Pro Cancel');
            console.log('Error', err);
          }}
        />
      </div>
      <div>Cancel Anytime</div>
    </>
  );
}
export default Payment;
