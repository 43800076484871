import React from 'react';
import {useSelector} from 'react-redux';
import PageHeader from '../../components/PageHeader';
import Confetti from 'react-confetti';
import {useWindowSize} from 'react-use';
import FreeSuccess from './freeSuccess';
import ProSuccess from './proSuccess';
import {Redirect} from 'react-router-dom';

const RegistrationSuccess = () => {
  // const dispatch = useDispatch();
  const {width, height} = useWindowSize();
  const {registerStatus} = useSelector(({registerStatusReducer}) => ({
    registerStatus: registerStatusReducer.registerStatus,
  }));

  if (!registerStatus) {
    return (
      <Redirect
        to={{
          pathname: '/search',
        }}
      />
    );
  }

  return (
    <>
      <div style={{backgroundColor:"#fff"}} >
      <PageHeader />
      <Confetti width={width} height={height} recycle={false} />
      <div style={{textAlign: 'center', height: '95vh'}}>
        {registerStatus.pro ? <ProSuccess /> : <FreeSuccess />}
      </div>
      </div>
    </>
  );
};
export default RegistrationSuccess;
