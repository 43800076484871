import React from 'react';
import {useDispatch} from 'react-redux';
import moment from 'moment';
import {
  ConfigProvider,
  Button,
  List,
  Tag,
  Popconfirm
} from 'antd';
import {DeleteOutlined, SearchOutlined} from '@ant-design/icons';
import {Link} from 'react-router-dom';
import {
  deleteDomain,
  openRenewDomainPopup,
} from '../../actions/mydomainsAction';

const customizeRenderEmpty = () => (
  <div style={{textAlign: 'center'}}>
    <SearchOutlined style={{fontSize: 40, padding: '40px'}} />
    <h3>Search & Register Your First Domain</h3>
    <Link to={`/search`} style={{color: '#037cff', fontSize: '16px'}}>
      <Button type="primary" size="large">Search Domain</Button>
    </Link>
  </div>
);

const FreeDomainList = ({domains}) => {
  const dispatch = useDispatch();
  const isDomainActive = (domainObject) => {
    return domainObject.expiresIn > 0;
  };

  return (
    <ConfigProvider renderEmpty={customizeRenderEmpty}>
      <List
        style={{background: '#fff'}}
        itemLayout="horizontal"
        dataSource={domains}
        bordered
        size="large"
        renderItem={(item) => (
          <List.Item
            actions={[
              (isDomainActive(item) && (
                <Link
                  to={`/setup/${item.domain}`}
                  style={{color: '#037cff', fontSize: '16px'}}
                >
                  <Button size="middle" type="primary">
                    Manage Domain
                  </Button>
                </Link>
              )) || (
                <Button size="middle" type="primary" disabled>
                  Manage Domain
                </Button>
              ),
              <Popconfirm
                title="Are you sure to delete this domain?"
                onConfirm={() => {
                  dispatch(deleteDomain(item.domain));
                }}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <Button type="link" size="middle" style={{color: 'red'}}>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>,
            ]}
          >
            <List.Item.Meta
              title={
                <>
                  <div>
                    {(isDomainActive(item) && (
                      <Link
                        to={`/setup/${item.domain}`}
                        style={{color: '#037cff', fontSize: '16px'}}
                      >
                        {item.domain}.co.vu
                        <Tag color="green" style={{marginLeft: '10px'}}>
                          FREE
                        </Tag>
                      </Link>
                    )) || (
                      <>
                        {item.domain}.co.vu
                        <Tag color="default" style={{marginLeft: '10px'}}>
                          EXPIRED
                        </Tag>
                      </>
                    )}
                  </div>
                  {(isDomainActive(item) && (
                    <div
                      style={{
                        color: '#c5c5c5',
                        fontSize: '14px',
                        paddingTop: '10px',
                      }}
                    >
                      Expires in{' '}
                      <span style={{color: '#717171'}}>{item.expiresIn}</span>{' '}
                      Days |{' '}
                      {item.canRenew ? (
                        <>
                          {' '}
                          <Button
                            type="primary"
                            size="small"
                            onClick={() => {
                              dispatch(openRenewDomainPopup(item));
                            }}
                          >
                            Renew Now
                          </Button>
                        </>
                      ) : (
                        <>
                          Renew between{' '}
                          <span style={{color: '#717171'}}>
                            {moment(item.renewStart).format('MMM Do YYYY')} -{' '}
                            {moment(item.renewEnd).format('MMM Do YYYY')}{' '}
                          </span>
                        </>
                      )}
                    </div>
                  )) || (
                    <div
                      style={{
                        color: '#c5c5c5',
                        fontSize: '14px',
                        paddingTop: '10px',
                      }}
                    >
                      Domain Expired since{' '}
                      {moment(item.renewEnd).format('MMM Do YYYY')}
                    </div>
                  )}
                </>
              }
            />
          </List.Item>
        )}
      />
    </ConfigProvider>
  );
};
export default FreeDomainList;
