import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Skeleton} from 'antd';
import {getMyDomains} from '../../actions/mydomainsAction';
import {isLoading} from '../../utils/asyncStatusHelper';
import PageHeader from '../../components/PageHeader';
import RenewDomain from './renewDomain';
import FreeDomainList from './freeDomainList';
import ProDomainList from './proDomainList';

const MyDomains = () => {
  const dispatch = useDispatch();
  const {myDomainsAsyncStatus, domains, isPro} = useSelector(
    ({myDomainsReducer, sessionReducer}) => ({
      myDomainsAsyncStatus: myDomainsReducer.myDomainsAsyncStatus,
      domains: myDomainsReducer.domains,
      isPro: sessionReducer.session?.plan?.isPro
      
    })
  );

  useEffect(() => {
    dispatch(getMyDomains());
  }, [dispatch]);

  

  return (
    <>
      <PageHeader />

      <div style={{width: '1100px', margin: 'auto'}}>
        <h2 style={{marginTop: '30px', marginBottom: '30px'}}>My Domains</h2>
        {isLoading(myDomainsAsyncStatus) ? (
          <div style={{background: '#ffffff', padding: '40px'}}>
            <Skeleton active paragraph={{rows: 3, width: 10}} />
          </div>
        ) : (
          <>
            {isPro ? (
              <ProDomainList domains={domains} />
            ) : (
              <FreeDomainList domains={domains} />
            )}
          </>
        )}
      </div>
      <RenewDomain />
    </>
  );
};
export default MyDomains;
