import {toast} from 'react-toastify';
import get from 'lodash/get';

export function handleSuccess(response, autoClose = 5000) {
  const statusCode = get(response, ['status']);
  const successMsg = get(response, ['data', 'successMsg']);

  // eslint-disable-next-line default-case
  switch (statusCode) {
    case 200:
    case 201:
      successMsg && toast(successMsg, {type: 'success', autoClose: autoClose});
      break;
  }
}

export function handleError(error, autoClose = 5000) {
  const statusCode = get(error, ['response', 'status']);
  const errorMsg = get(error, ['response', 'data', 'errorMsg']);

  // eslint-disable-next-line default-case
  switch (statusCode) {
    case 400:
    case 401:
      errorMsg && toast(errorMsg, {type: 'error', autoClose: autoClose});
      break;
  }
}
