import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button} from 'antd';
import {registerDomain} from '../../actions/searchDomainAction';
import {isLoading, isSuccess} from '../../utils/asyncStatusHelper';

const RegisterProDomain = () => {
  const dispatch = useDispatch();
  const {upgradePlanAsyncStatus, registerAsyncStatus, domain, session} =
    useSelector(({domainSearchReducer, sessionReducer, userPlanReducer}) => ({
      upgradePlanAsyncStatus: userPlanReducer.upgradePlanAsyncStatus,
      registerAsyncStatus: domainSearchReducer.registerAsyncStatus,
      domain: domainSearchReducer.domain,
      session: sessionReducer.session,
    }));
  const {
    plan: {isPro, limits: {domain: domainLimit} = {}} = {},
    usage: {allDomains: usageDomain = 0},
  } = session;

  if (!isPro) {
    return null;
  }

  if (isSuccess(upgradePlanAsyncStatus)) return null;

  if (!(usageDomain < domainLimit)) {
    return null;
  }

  return (
    <>
      <div style={{marginTop: '30px', marginBottom: '25px'}}></div>
      <Button
        type="primary"
        size="large"
        loading={isLoading(registerAsyncStatus)}
        onClick={() => {
          dispatch(registerDomain(domain));
        }}
      >
        Register Domain
      </Button>
    </>
  );
};
export default RegisterProDomain;
