import axios from 'axios';
import {showLoading, hideLoading} from 'react-redux-loading-bar';
import mixpanel from '../utils/mixpanel';

export const GET_DNS_CUSTOM_REQUEST = 'GET_DNS_CUSTOM_REQUEST';
export const GET_DNS_CUSTOM_SUCCESS = 'GET_DNS_CUSTOM_SUCCESS';
export const GET_DNS_CUSTOM_ERROR = 'GET_DNS_CUSTOM_ERROR';

export const CREATE_DNS_CUSTOM_REQUEST = 'CREATE_DNS_CUSTOM_REQUEST';
export const CREATE_DNS_CUSTOM_SUCCESS = 'CREATE_DNS_CUSTOM_SUCCESS';
export const CREATE_DNS_CUSTOM_ERROR = 'CREATE_DNS_CUSTOM_ERROR';

export const EDIT_DNS_CUSTOM_REQUEST = 'EDIT_DNS_CUSTOM_REQUEST';
export const EDIT_DNS_CUSTOM_SUCCESS = 'EDIT_DNS_CUSTOM_SUCCESS';
export const EDIT_DNS_CUSTOM_ERROR = 'EDIT_DNS_CUSTOM_ERROR';

export const DELETE_DNS_CUSTOM_REQUEST = 'DELETE_DNS_CUSTOM_REQUEST';
export const DELETE_DNS_CUSTOM_SUCCESS = 'DELETE_DNS_CUSTOM_SUCCESS';
export const DELETE_DNS_CUSTOM_ERROR = 'DELETE_DNS_CUSTOM_ERROR';

export const getDNSRecords = (domain) => async (dispatch) => {
  
  dispatch({type: GET_DNS_CUSTOM_REQUEST,payload:{domain:domain}});
  dispatch(showLoading());

  try {
    //const {data} = await axios.post('/api/dns/get', {domain});
    const {data} = await axios.post('/api/v2/dns/list', {domain});
    dispatch({type: GET_DNS_CUSTOM_SUCCESS, payload: {domain,...data}})
    dispatch(hideLoading());
  } catch (error) {
    dispatch({type: GET_DNS_CUSTOM_ERROR, payload: {domain,...error.data}});
    dispatch(hideLoading());
  }
};

export const createDNSRecord = (data) => {
  return async (dispatch) => {
    dispatch({type: CREATE_DNS_CUSTOM_REQUEST});
    dispatch(showLoading());
    try {
      mixpanel.track("Create DNS Record");
      //const {data: payload} = axios.post('/api/dns/customdns/create', data);
      const {data: payload} = await axios.post('/api/v2/dns/create', data);
      
      dispatch({type: CREATE_DNS_CUSTOM_SUCCESS, payload});
      dispatch(getDNSRecords(data.domain));
      dispatch(hideLoading());
    } catch (error) {
      dispatch({type: CREATE_DNS_CUSTOM_ERROR, payload: error.data});
      dispatch(hideLoading());
    }
  };
};

export const updateDNSRecord = (data) => {
  return async (dispatch) => {
    dispatch({type: EDIT_DNS_CUSTOM_REQUEST});
    dispatch(showLoading());
    try {
      mixpanel.track("Update DNS Record");
      //const {data: payload} = axios.post('/api/dns/customdns/create', data);
      const {data: payload} = await axios.post('/api/v2/dns/update', data);
      dispatch({type: EDIT_DNS_CUSTOM_SUCCESS, payload});
      dispatch(getDNSRecords(data.domain));
      dispatch(hideLoading());
    } catch (error) {
      dispatch({type: EDIT_DNS_CUSTOM_ERROR, payload: error.data});
      dispatch(hideLoading());
    }
  };
};

export const deleteDNSRecord = (domain, id) => async (dispatch) => {
  dispatch({type: DELETE_DNS_CUSTOM_REQUEST, payload: id});
  dispatch(showLoading());
  try {
    
    /*
      const {data: payload} = await axios.post('/api/dns/customdns/delete', {
        domain,
        recordId: id,
      });
    */
    
    const {data: payload} = await axios.post('/api/v2/dns/delete', {
      domain,
      recordId: id,
    });
    dispatch({type: DELETE_DNS_CUSTOM_SUCCESS, payload});
    dispatch(getDNSRecords(domain));
    dispatch(hideLoading());
  } catch (error) {
    dispatch({type: DELETE_DNS_CUSTOM_ERROR, payload: error.data});
    dispatch(hideLoading());
  }
};
