import axios from 'axios';
import {showLoading, hideLoading} from 'react-redux-loading-bar';
import {push} from 'connected-react-router';
import {SESSION_SUCCESS} from './authAction';
import mixpanel from '../utils/mixpanel';

export const SEARCH_DOMAIN_START = 'SEARCH_DOMAIN_START';
export const SEARCH_DOMAIN_REQUEST = 'SEARCH_DOMAIN_REQUEST';
export const SEARCH_DOMAIN_SUCCESS = 'SEARCH_DOMAIN_SUCCESS';
export const SEARCH_DOMAIN_ERROR = 'SEARCH_DOMAIN_ERROR';
export const SEARCH_DOMAIN_RESET = 'SEARCH_DOMAIN_RESET';

export const REGISTER_DOMAIN_REQUEST = 'REGISTER_DOMAIN_REQUEST';
export const REGISTER_DOMAIN_SUCCESS = 'REGISTER_DOMAIN_SUCCESS';
export const REGISTER_DOMAIN_ERROR = 'REGISTER_DOMAIN_ERROR';

export const searchDomainStartAction = (data) => (dispatch) => {
  dispatch({type: SEARCH_DOMAIN_START, data});
};

export const searchDomainResetAction = () => {
  return (dispatch) => {
    dispatch({type: SEARCH_DOMAIN_RESET});
  };
};

export const searchDomainRequestAction = () => {
  return {type: SEARCH_DOMAIN_REQUEST};
};

export const searchDomainSuccessAction = (data) => {
  return {type: SEARCH_DOMAIN_SUCCESS, data};
};

export const searchDomainErrorAction = (error) => {
  return {type: SEARCH_DOMAIN_ERROR, error};
};

export const searchDomain = (search) => async (dispatch, getState) => {
  dispatch({type: SEARCH_DOMAIN_REQUEST});
  dispatch(showLoading());
  try {
    mixpanel.track("Domain Search");
    const {data} = await axios.post('/api/domain/searchDomain', search);
    dispatch({type: SEARCH_DOMAIN_SUCCESS, payload: data});
    dispatch(hideLoading());
  } catch (error) {
    console.log(error);
  }
};

export const registerDomain = (domain) => async (dispatch) => {
  dispatch({type: REGISTER_DOMAIN_REQUEST});
  dispatch(showLoading());
  try {
    mixpanel.track("Register Domain");
    const {
      data: {data, session},
    } = await axios.post('/api/v2/domain/register', {
      domain,
    });
    dispatch({type: REGISTER_DOMAIN_SUCCESS, payload: data});
    dispatch({type: SESSION_SUCCESS, payload: session});
    dispatch(push('/search/success'));
  } catch (error) {
    dispatch({type: REGISTER_DOMAIN_ERROR, payload: error.data});
    dispatch(hideLoading());
  }
};
