import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {upgradePlan} from '../../actions/planAction';
import PayPalBtn from '../../components/PaypalButton';


function Payment() {
  const dispatch = useDispatch();
  const {
    session:{
      id,
      email,
      paymentOptions: {
        key: clientId,
        plan: {id: planId, amount: planAmount},
      },
    },
  } = useSelector(({sessionReducer}) => ({
    session: sessionReducer.session,
  }));

  return (
    <div
      style={{
        padding: '10px 48px',
      }}
    >
      <PayPalBtn
        amount="4"
        currency="USD"
        clientId={clientId}
        createSubscription={(data, actions) => {
          return actions.subscription.create({
            plan_id: planId,
            custom_id: JSON.stringify({id, email})
          });
        }}
        onApprove={(data, detail) => {
          dispatch(upgradePlan(data.subscriptionID));
          console.log(data.subscriptionID,data, detail);
        }}
        catchError={(err) => {
          console.log('Paypal CatchError', err);
        }}
        onError={(err) => {
          console.log('Error', err);
        }}
        onCancel={(err) => {
          console.log('Error', err);
        }}
      />
    </div>
  );
}
export default Payment;
