import React from 'react';
import Helmet from 'react-helmet';
import {Button} from 'antd';
import GoogleLogin from 'react-google-login';
import {GoogleOutlined} from '@ant-design/icons';
import Features from './Features/Features';
import Faq from './Faq/Faq';
import UnauthorizedPageHeader from '../../components/PageHeader/UnauthorizedPageHeader';
import styles from './style.module.css';
import {useDispatch} from 'react-redux';
import {loginGmail} from '../../actions/authAction';

const Landingpage = () => {
  const dispatch = useDispatch();
  return (
    <>
      <Helmet title="co.vu | Get a Free Domain for your crazy idea" />
      <UnauthorizedPageHeader />
      <div className={styles['top-block']}>
        <h1 className={styles['heading']}>
          Get a Free Domain for your crazy idea
        </h1>
        <h4 className={styles['sub-heading']}>
          co.vu give you free domain name that you can use to host your Personal
          Website, Resume, Blog, Online Shop and many more... Create a identity
          for yourself.
        </h4>
        <div style={{textAlign: 'center', marginTop: '30px'}}>
          {/* <div style={{margin: '25px', maxWidth: '400px', margin: '25px auto', padding:'10px'}}>
            <Input addonBefore={<strong>http://</strong>} suffix=".co.vu" size="large" enterButton="Search" defaultValue="mysite" />
          </div> */}
          <GoogleLogin
            clientId="183094191766-io6rl63td2o2a879olmf6gp46bvqul98.apps.googleusercontent.com"
            buttonText="Signup With Google"
            theme="dark"
            accessType="offline"
            scope="openid profile email"
            render={(renderProps) => (
              <Button
                type="primary"
                size="large"
                icon={<GoogleOutlined />}
                onClick={renderProps.onClick}
                // disabled={renderProps.disabled}
              >
                Signup with Google
              </Button>
            )}
            onSuccess={(data) => {
              dispatch(loginGmail(data.tokenId));
            }}
            onFailure={(data) => {}}
          ></GoogleLogin>
        </div>
      </div>
      <Features />
      {/* <Pricing /> */}
      <Faq />
      {/* <Footer /> */}
    </>
  );
};

export default Landingpage;
