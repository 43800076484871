import {
  REGISTER_DOMAIN_REQUEST,
  REGISTER_DOMAIN_SUCCESS,
} from '../actions/searchDomainAction';
import {INIT, LOADING, SUCCESS} from '../utils/asyncStatusHelper';

const initialState = {
  registerAsyncStatus: INIT,
  registerStatus: null,
};

export default function registerStatus(state = initialState, {type, payload}) {
  switch (type) {
    case REGISTER_DOMAIN_REQUEST:
      return {
        ...state,
        ...payload,
        registerAsyncStatus: LOADING,
      };
    case REGISTER_DOMAIN_SUCCESS:
      return {
        ...state,
        registerStatus: payload,
        registerAsyncStatus: SUCCESS,
      };
    default:
      return state;
  }
}
