import {
  GET_USER_PLAN_REQUEST,
  GET_USER_PLAN_SUCCESS,
  GET_USER_PLAN_ERROR,
  UPGRADE_PLAN_REQUEST,
  UPGRADE_PLAN_SUCCESS,
  DOWNGRADE_PLAN_REQUEST
} from '../actions/planAction';
import {INIT, LOADING, SUCCESS, ERROR} from '../utils/asyncStatusHelper';

const initialState = {
  getUserPlanAsyncStatus: INIT,
  downgradePlanAsyncStatus: INIT,
  upgradePlanAsyncStatus: INIT,
  plan: null,
};

export default function userPlan(state = initialState, {type, payload}) {
  switch (type) {
    case GET_USER_PLAN_REQUEST:
      return {
        ...state,
        getUserPlanAsyncStatus: LOADING,
      };
    case GET_USER_PLAN_SUCCESS:
      return {
        ...state,
        plan: payload,
        getUserPlanAsyncStatus: SUCCESS,
      };
    case GET_USER_PLAN_ERROR:
      return {
        ...state,
        ...payload,
        getUserPlanAsyncStatus: ERROR,
      };
    case UPGRADE_PLAN_REQUEST:
      return {
        ...state,
        upgradePlanAsyncStatus: LOADING,
      };
    case UPGRADE_PLAN_SUCCESS:
      return {
        ...state,
        upgradePlanAsyncStatus: SUCCESS,
      };
    case DOWNGRADE_PLAN_REQUEST:
      return {
        ...state,
        downgradePlanAsyncStatus: LOADING,
      };
    default:
      return state;
  }
}
