import axios from 'axios';
import {showLoading, hideLoading} from 'react-redux-loading-bar';
import {push} from 'connected-react-router';
// import {toast} from 'react-toastify';
import {track, register, flattenSession} from '../utils/trackingUtils';
import {handleError} from '../utils/toastUtils';

export const SESSION_REQUEST = 'SESSION_REQUEST';
export const SESSION_SUCCESS = 'SESSION_SUCCESS';
export const SESSION_ERROR = 'SESSION_ERROR';

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const FORGOTPASS_REQUEST = 'FORGOTPASS_REQUEST';
export const FORGOTPASS_SUCCESS = 'FORGOTPASS_SUCCESS';
export const FORGOTPASS_ERROR = 'FORGOTPASS_ERROR';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export const getSession = () => async (dispatch) => {
  dispatch({type: SESSION_REQUEST});
  dispatch(showLoading());
  try {
    const {
      data: {data},
    } = await axios.get('/api/v2/user/session');
    dispatch({type: SESSION_SUCCESS, payload: data});
    dispatch(hideLoading());
  } catch (error) {
    console.log(error);
    dispatch({type: SESSION_ERROR, data: error.data});
    dispatch(hideLoading());
    handleError(error);
  }
};

// export const signupRequestAction = () => {
//   return {type: SIGNUP_REQUEST};
// };

// export const signupSuccessAction = (data) => {
//   return {type: SIGNUP_SUCCESS, data};
// };

// export const signupErrorAction = (error) => {
//   return {type: SIGNUP_ERROR, error};
// };

// export const signupAction = (data) => {
//   return (dispatch) => {
//     dispatch(signupRequestAction());
//     dispatch(showLoading());
//     return axios(
//       Object.assign({}, baseAjaxConfig, {
//         method: 'post',
//         url: '/api/user/signup',
//         data,
//       })
//     )
//       .then(function (response) {
//         dispatch(hideLoading());
//         dispatch(signupSuccessAction(response.data));
//         register(flattenSession(response.data.SESSION, 'Email'));
//         track('Signup', {channel: 'Email'});
//         return Promise.resolve(response.data);
//       })
//       .catch(function (error) {
//         dispatch(hideLoading());
//         dispatch(signupErrorAction(error.data));
//         track('Signup Error', {channel: 'Email'});
//         handleError(error);
//         return Promise.reject(error.data);
//       });
//   };
// };

export const loginAction = (data) => async (dispatch, getState) => {
  dispatch({type: LOGIN_REQUEST});
  dispatch(showLoading());

  try {
    const {
      data: {SESSION: session},
    } = await axios.post('/api/user/login', data);
    dispatch({type: LOGIN_SUCCESS, payload: session});
    dispatch(hideLoading());
    dispatch(push('/mydomains'));
    register(flattenSession(session, 'Email'));
    track('Sign In', {channel: 'Email'});
  } catch (error) {
    dispatch({type: LOGIN_ERROR, payload: error.data});
    dispatch(hideLoading());
    handleError(error);
    track('Sign In Error', {channel: 'Email'});
  }
};

// export const loginFacebookAction = (data) => {
//   return (dispatch) => {
//     dispatch(loginRequestAction());
//     dispatch(showLoading());

//     return axios(
//       Object.assign({}, baseAjaxConfig, {
//         method: 'post',
//         url: '/api/user/loginFacebook',
//         data,
//       })
//     )
//       .then(function (response) {
//         dispatch(loginSuccessAction(response.data));
//         dispatch(hideLoading());
//         register(flattenSession(response.data.SESSION, response.data.channel));
//         if (response.data.isNew) {
//           track('Signup', {channel: response.data.channel});
//         } else {
//           track('Sign In', {channel: response.data.channel});
//         }
//         return Promise.resolve(response.data);
//       })
//       .catch(function (error) {
//         console.log(error);
//         dispatch(hideLoading());
//         return Promise.reject(error.data);
//       });
//   };
// };

export const loginGmail = (tokenId) => async (dispatch) => {
  dispatch({type: LOGIN_REQUEST});
  dispatch(showLoading());
  try {
    const {
      data: {SESSION: session},
    } = await axios.post('/api/user/loginonetap', {idToken: tokenId});
    dispatch({type: LOGIN_SUCCESS, payload: session});
    dispatch(hideLoading());
    dispatch(push('/mydomains'));
  } catch (error) {
    dispatch({type: LOGIN_ERROR, payload: error.data});
    dispatch(hideLoading());
    handleError(error);
    track('Sign In Error', {channel: 'Email'});
  }
};
// export function forgotPassRequestAction() {
//   return {type: FORGOTPASS_REQUEST};
// }

// export function forgotPassSuccessAction(data) {
//   return {type: FORGOTPASS_SUCCESS, data};
// }

// export function forgotPassErrorAction(error) {
//   return {type: FORGOTPASS_ERROR, error};
// }

// export function forgotPassAction(data) {
//   return (dispatch) => {
//     dispatch(forgotPassRequestAction());

//     return axios(
//       Object.assign({}, baseAjaxConfig, {
//         method: 'post',
//         url: '/api/user/forgotpassword',
//         data,
//       })
//     )
//       .then(function (response) {
//         dispatch(forgotPassSuccessAction(response.data));
//         return Promise.resolve(response.data);
//       })
//       .catch(function (error) {
//         dispatch(forgotPassErrorAction(error.data));
//         return Promise.reject(response.data);
//       });
//   };
// }

export const logout = () => async (dispatch) => {
  dispatch({type: LOGOUT_REQUEST});
  dispatch(showLoading());
  try {
    await axios.post('/api/user/logout', {});
    dispatch({type: LOGOUT_SUCCESS});
    dispatch(push('/'));
  } catch (error) {
    dispatch({type: LOGOUT_ERROR, payload: error.data});
    dispatch(hideLoading());
    dispatch(push('/'));
  }
};

// export const ResetPasswordRequestAction = () => {
//   return {type: RESET_PASSWORD_REQUEST};
// };

// export const ResetPasswordSuccessAction = (data) => {
//   return {type: RESET_PASSWORD_SUCCESS, data};
// };

// export const ResetPasswordErrorAction = (error) => {
//   return {type: RESET_PASSWORD_ERROR, error};
// };

// export const ResetPasswordAction = (data) => {
//   return (dispatch) => {
//     dispatch(showLoading());
//     dispatch(ResetPasswordRequestAction());
//     return axios(
//       Object.assign({}, baseAjaxConfig, {
//         method: 'post',
//         url: '/api/user/resetpassword',
//         data: data,
//       })
//     )
//       .then(function (response) {
//         dispatch(ResetPasswordSuccessAction(response.data));
//         Promise.resolve(response.data);
//       })
//       .catch(function (error) {
//         dispatch(hideLoading());
//         dispatch(ResetPasswordErrorAction(error.data));
//         track('ResetPass Error', {channel: 'Email'});
//         handleError(error);
//         promise.reject(response.data);
//       });
//   };
// };
