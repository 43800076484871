import React from 'react';
import {useDispatch} from 'react-redux';
import {ConfigProvider, Button, List, Tag, Popconfirm} from 'antd';
import {DeleteOutlined, SearchOutlined} from '@ant-design/icons';
import {Link} from 'react-router-dom';
import {
  deleteDomain
} from '../../actions/mydomainsAction';


const CustomizeRenderEmpty = () => (
  <div style={{textAlign: 'center'}}>
    <SearchOutlined style={{fontSize: 40, padding: '40px'}} />
    <h3>Search & Register Your First Domain</h3>
    <Link to={`/search`} style={{color: '#037cff', fontSize: '16px', marginTop: '40px'}}>
      <Button type="primary" size="large">Search Domain</Button>
    </Link>
  </div>
);

const ProDomainList = ({domains}) => {
  const dispatch = useDispatch();
  return (
    <ConfigProvider renderEmpty={CustomizeRenderEmpty}>
    <List
      style={{background: '#fff'}}
      itemLayout="horizontal"
      dataSource={domains}
      bordered
      size="large"
      renderItem={(item) => (
        <List.Item
          actions={[
            <Link
              to={`/setup/${item.domain}`}
              style={{color: '#037cff', fontSize: '16px'}}
            >
              <Button size="middle" type="primary">
                Manage Domain
              </Button>
            </Link>,
            <Popconfirm
              title="Are you sure to delete this domain?"
              onConfirm={() => {
                dispatch(deleteDomain(item.domain));
              }}
              onCancel={(e) => {}}
              okText="Yes"
              cancelText="No"
            >
              <Button type="link" size="middle" style={{color: 'red'}}>
                <DeleteOutlined />
              </Button>
            </Popconfirm>,
          ]}
        >
          <List.Item.Meta
            title={
              <>
                <div>
                  <Link
                    to={`/setup/${item.domain}`}
                    style={{color: '#037cff', fontSize: '16px'}}
                  >
                    {item.domain}.co.vu
                  </Link>{' '}
                  <Tag color="#fe4365">PRO</Tag>
                </div>
              </>
            }
          />
        </List.Item>
      )}
    />
    </ConfigProvider>
  );
};
export default ProDomainList;
