import React from 'react';
import {useSelector} from 'react-redux';
import PageHeader from '../../components/PageHeader';
import FreeEmpty from './freeEmpty';
const Pro = () => {
  const {
    // eslint-disable-next-line no-empty-pattern
    domainSearchReducer: {},
  } = useSelector(({domainSearchReducer, sessionReducer}) => ({
    domainSearchReducer,
    session: sessionReducer.session,
  }));

  return (
    <>
      <PageHeader />
      <FreeEmpty></FreeEmpty>
    </>
  );
};
export default Pro;
