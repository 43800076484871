import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Route, Redirect} from 'react-router-dom';
import PageLoader from '../PageLoader';
import {isInit, isLoading} from '../../utils/asyncStatusHelper';
import {getSession} from '../../actions/authAction';

const PrivateRoute = ({component: Component, ...rest}) => {
  const dispatch = useDispatch();
  const {sessionAsyncStatus, loggedIn} = useSelector(({sessionReducer}) => ({
    loggedIn: sessionReducer.loggedIn,
    sessionAsyncStatus: sessionReducer.sessionAsyncStatus,
  }));

  const getComponent = (props)=>{
    let component;
    if(loggedIn){
      if(props.match.path === "/login" || props.match.path === "/"){
        component = <Redirect to={{pathname: '/mydomains'}}/>
      }else{
        component = <Component {...props} />
      }
    }else{
      if(props.match.path === "/login" || props.match.path === "/"){
        component = <Component {...props} />
      }else{
        component = <Redirect to={{pathname: '/'}}/>
      }
    }
    
    return component;
  }

  useEffect(() => {
    if (isInit(sessionAsyncStatus)) {
      dispatch(getSession());
    }
  }, [dispatch, sessionAsyncStatus]);

  if (isInit(sessionAsyncStatus) || isLoading(sessionAsyncStatus)) {
    return <PageLoader />;
  }

  

  return (
    <Route
      {...rest}
      render={getComponent}
    />
  );
};

export default PrivateRoute;
