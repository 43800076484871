import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Modal} from 'antd';
import moment from 'moment';
import {
  closeRenewDomainPopup,
  renewDomain,
} from '../../actions/mydomainsAction';
import {isLoading} from '../../utils/asyncStatusHelper';

const RenewDomain = () => {
  const dispatch = useDispatch();
  const {
    renewDomainAsyncStatus,
    renewPopupOpen,
    renewPopupDetails = {},
  } = useSelector(({myDomainsReducer}) => ({
    renewDomainAsyncStatus: myDomainsReducer.renewDomainAsyncStatus,
    renewPopupOpen: myDomainsReducer.renewPopupOpen,
    renewPopupDetails: myDomainsReducer.renewPopupDetails,
  }));

  return (
    <>
      <Modal
        title={<div style={{textAlign: 'center'}}>Renew Domain</div>}
        visible={renewPopupOpen}
        onOk={() => {}}
        onCancel={() => {
          dispatch(closeRenewDomainPopup());
        }}
        footer={null}
      >
        <div style={{textAlign: 'center'}}>
          <p>
            <strong>{renewPopupDetails.domain}.co.vu</strong>
          </p>
          <p>
            Your Free Domain will expire on{' '}
            <strong>
              {moment(renewPopupDetails.expiryDate).format('MMM Do YYYY')}
            </strong>{' '}
            ({renewPopupDetails.expiresIn}
            days from now)
          </p>
          <p>
            Renew your domain now and extend it by <strong>21 days</strong>
          </p>
          <Button
            type="primary"
            size="large"
            loading={isLoading(renewDomainAsyncStatus)}
            onClick={() => {
              dispatch(renewDomain(renewPopupDetails.domain));
            }}
          >
            Renew Now
          </Button>
        </div>
      </Modal>
    </>
  );
};
export default RenewDomain;
