import React from 'react';
import {useSelector} from 'react-redux';
import {Row, Col} from 'antd';
import Payment from './payment';
import Features from './features';
import Processing from './processing';
import Upgraded from './upgraded';
import { isLoading, isSuccess } from '../../utils/asyncStatusHelper';

const FreeEmpty = () => {
  const {upgradePlanAsyncStatus, plan} = useSelector(
    ({sessionReducer, userPlanReducer}) => ({
      upgradePlanAsyncStatus: userPlanReducer.upgradePlanAsyncStatus,
      getUserPlanAsyncStatus: sessionReducer.sessionAsyncStatus,
      plan: sessionReducer.session.plan,
    })
  );

  return (
    <>
      <div style={{marginTop: '30px', marginBottom: '25px'}}></div>

      <div
        style={{
          padding: '20px',
          color: '#FE4364',
          width: '1100px',
          margin: '20px auto',
          textAlign: 'center',
          fontWeight: '800',
          fontSize: '28px'
        }}
      >        
          Activate Your <span style={{background:"#FE4364", color: '#fff', padding: '5px 20px', borderRadius: '5px'}}>PRO</span> Membership
      </div>     

      <Row>
        <Col span={7} offset={5} style={{background: '#ffffff'}}>
          <Features />
        </Col>
        <Col span={7}>
          {plan.membership !== "ACTIVE" && !isLoading(upgradePlanAsyncStatus) && <Payment></Payment>}
          {isLoading(upgradePlanAsyncStatus) && <Processing></Processing>}
          {isSuccess(upgradePlanAsyncStatus) && <Upgraded></Upgraded>}
        </Col>
      </Row>
    </>
  );
};
export default FreeEmpty;
