import {PayPalButton} from 'react-paypal-button-v2';
import React from 'react';
export function PayPalBtn(props) {
  const {
    amount,
    currency,
    createSubscription,
    onApprove,
    catchError,
    onError,
    onCancel,
    clientId
  } = props;
  return (
    <PayPalButton
      amount={amount}
      currency={currency}
      createSubscription={(data, details) => createSubscription(data, details)}
      onApprove={(data, details) => onApprove(data, details)}
      onError={(err) => onError(err)}
      catchError={(err) => catchError(err)}
      onCancel={(err) => onCancel(err)}
      options={{
        clientId,
        vault: true,
      }}
      style={{
        shape: 'rect',
        color: 'silver',
        layout: 'vertical',
        label: 'paypal',
      }}
    />
  );
}
export default PayPalBtn;
