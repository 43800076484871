import {
  GET_DNS_CUSTOM_REQUEST,
  GET_DNS_CUSTOM_SUCCESS,
  DELETE_DNS_CUSTOM_REQUEST,
} from '../actions/dnsCustomAction';

// import {SAVE_DNS_URLFORWARD_SUCCESS} from 'actions/dnsUrlForwardingAction';

// import {
//   GET_DNS_CUSTOM_REQUEST,
//   GET_DNS_CUSTOM_SUCCESS,
//   CREATE_DNS_CUSTOM_REQUEST,
//   CREATE_DNS_CUSTOM_SUCCESS,
//   DELETE_DNS_CUSTOM_REQUEST,
//   DELETE_DNS_CUSTOM_SUCCESS,
// } from '../actions/dnsCustomAction';

import {INIT, LOADING, SUCCESS} from '../utils/asyncStatusHelper';
// import {createReducer} from 'utils/reducerUtils';

const defaultState = {
  getDNSAsyncStatus: INIT,
  deleteDNSAsyncStatus: INIT,
  dnsRecords: [],
  domains:{},
  setupType: null,
};

export default function domainSettings(state = defaultState, {type, payload}) {
  switch (type) {
    case GET_DNS_CUSTOM_REQUEST:
      return {
        ...state,
        getDNSAsyncStatus: LOADING,
      };
    case GET_DNS_CUSTOM_SUCCESS:
      
    return {
        ...state,
        ...payload,
        getDNSAsyncStatus: SUCCESS,
        domains:((_domains)=>{
          _domains[payload.domain] = payload;
          return _domains;
        })(state.domains)
      };
    case DELETE_DNS_CUSTOM_REQUEST:
      return {
        ...state,
        deleteDNSAsyncStatus: LOADING,
        dnsRecords: state.dnsRecords.map((item) => {
          if (item.id === payload) {
            item.isDeleting = true;
          }
          return item;
        }),
      };
    // case GET_DNS_CUSTOM_SUCCESS:
    //   return {
    //     ...state,
    //     ...payload,
    //     getDNSAsyncStatus: SUCCESS,
    //   };

    //   Object.assign({}, state, action.data, {asyncStatus: SUCCESS});
    // case SAVE_DNS_URLFORWARD_SUCCESS:
    // case DELETE_DNS_CUSTOM_SUCCESS:
    // case CREATE_DNS_CUSTOM_SUCCESS:
    //   return Object.assign({}, action.data.settings, {asyncStatus: SUCCESS});
    // case GET_DOMAIN_SETTINGS_ERROR:
    //   return Object.assign(
    //     {},
    //     state,
    //     {
    //       asyncStatus: ERROR,
    //     },
    //     action.error
    //   );
    default:
      return state;
  }
}
  