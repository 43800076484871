import React from 'react';
import {useSelector} from 'react-redux';
import moment from 'moment';
import {Result, Button, Descriptions} from 'antd';
import {Link} from 'react-router-dom';

const FreeSuccess = () => {
  const {registerStatus} = useSelector(({registerStatusReducer}) => ({
    registerStatus: registerStatusReducer.registerStatus,
  }));

  return (
    <Result
      style={{background: 'white'}}
      status="success"
      title={
        <>
          <strong
            style={{
              paddingRight: '7px',
              background: '#f2f2f2',
              padding: '5px 15px',
              marginRight: '6px',
              borderRadius: '4px',
            }}
          >
            {registerStatus.domain}.co.vu
          </strong>
          registered successfully for 21 days
        </>
      }
      subTitle={
        <div style={{width: '900px', margin: '30px auto'}}>
          <Descriptions layout="vertical" bordered>
            <Descriptions.Item label="Type">
              <strong>FREE Domain</strong>
            </Descriptions.Item>
            <Descriptions.Item label="Active  Till">
              <strong></strong>-{' '}
              <strong>
                {moment(registerStatus.expiryDate).format('MMM Do YYYY')}
              </strong>
            </Descriptions.Item>
            <Descriptions.Item label="Renewal Window">
              Between{' '}
              <strong>
                {moment(registerStatus.renewStart).format('MMM Do YYYY')}
              </strong>{' '}
              &{' '}
              <strong>
                {moment(registerStatus.renewEnd).format('MMM Do YYYY')}
              </strong>
            </Descriptions.Item>
          </Descriptions>
        </div>
      }
      extra={[
        <Link to="/search" key="search">
          <Button size="large">Back To Search</Button>
        </Link>,
        <Link to={`/setup/${registerStatus.domain}`} key="dnssetup">
          <Button type="primary" size="large">
            Manage DNS
          </Button>
        </Link>,
      ]}
    />
  );
};
export default FreeSuccess;
