import React from 'react';
import UnauthorizedPageHeader from '../../components/PageHeader/UnauthorizedPageHeader';

const ContactUs = () => {
    return (
        <>
            <UnauthorizedPageHeader />
            <div style={{textAlign: 'center'}}>
                <div style={{
                    width: '555px',
                    margin: 'auto',
                    background: '#ffffff',
                    padding: '41px',
                    marginTop: '80px',
                    height: '200px'
                }}>
                    <h1>Contact Us</h1>
                    <h3>
                        Have a question? Please drop as an email <b>codotvu@gmail.com</b>
                        
                    </h3>
                </div>
            </div>
        </>
    )
}

export default ContactUs;