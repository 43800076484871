import React, {Component} from 'react';
import s from './Features.module.css';

class Features extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    return (
      <div className={s['feature-block']}>
        <div className={s['features']}>
          <div className={s['feature']}>
            <div className={s['feature-logo']}>
              <svg
                class="svg-fill"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 25 25"
              >
                <path
                  style={{fill: '#5c5fbe'}}
                  d="M12.5 0A12.5 12.5 0 1 0 25 12.5 12.5 12.5 0 0 0 12.5 0zm5.364 9.843l-5.646 6a.5.5 0 0 1-.7.028l-3.353-3.025a.5.5 0 1 1 .67-.742l2.99 2.697 5.311-5.644a.5.5 0 1 1 .729.686z"
                />
              </svg>
            </div>
            <div className={s['feature-content']}>
              <div className={s['heading']} style={{color: '#5c5fbe'}}>
                Quick Setup
              </div>
              <div className={s['content']}>
                Easily connect your domain to Tumblr, Wordpress, Github Pages,
                Heroku etc
              </div>
            </div>
          </div>
          <div className={s['feature']}>
            <div className={s['feature-logo']}>
              <svg
                class="svg-fill"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 25 25"
              >
                <path
                  style={{fill: '#E91E63'}}
                  d="M12.5 0A12.5 12.5 0 1 0 25 12.5 12.5 12.5 0 0 0 12.5 0zm5.364 9.843l-5.646 6a.5.5 0 0 1-.7.028l-3.353-3.025a.5.5 0 1 1 .67-.742l2.99 2.697 5.311-5.644a.5.5 0 1 1 .729.686z"
                />
              </svg>
            </div>
            <div className={s['feature-content']}>
              <div className={s['heading']} style={{color: '#E91E63'}}>
                Domain Forwarding
              </div>
              <div className={s['content']}>
                Forward visitors to your domain <strong>Instagram</strong>,{' '}
                <strong>LinkedIn</strong>, <strong>Amazon Store</strong>
              </div>
            </div>
          </div>
          <div className={s['feature']}>
            <div className={s['feature-logo']}>
              <svg
                class="svg-fill"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 25 25"
              >
                <path
                  style={{fill: '#009688'}}
                  d="M12.5 0A12.5 12.5 0 1 0 25 12.5 12.5 12.5 0 0 0 12.5 0zm5.364 9.843l-5.646 6a.5.5 0 0 1-.7.028l-3.353-3.025a.5.5 0 1 1 .67-.742l2.99 2.697 5.311-5.644a.5.5 0 1 1 .729.686z"
                />
              </svg>
            </div>
            <div className={s['feature-content']}>
              <div className={s['heading']} style={{color: '#009688'}}>
                It's Free
              </div>
              <div className={s['content']}>
                Get two domains per account and use all the core features – for
                free for 90 days!
              </div>
            </div>
          </div>
          <div className={s['feature']}>
            <div className={s['feature-logo']}>
              <svg
                class="svg-fill"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 25 25"
              >
                <path
                  style={{fill: '#795548'}}
                  d="M12.5 0A12.5 12.5 0 1 0 25 12.5 12.5 12.5 0 0 0 12.5 0zm5.364 9.843l-5.646 6a.5.5 0 0 1-.7.028l-3.353-3.025a.5.5 0 1 1 .67-.742l2.99 2.697 5.311-5.644a.5.5 0 1 1 .729.686z"
                />
              </svg>
            </div>
            <div className={s['feature-content']}>
              <div className={s['heading']} style={{color: '#795548'}}>
                Managed DNS
              </div>
              <div className={s['content']}>
                Manage DNS settings like A record and CNAME
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Features;
