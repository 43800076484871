import {
  GET_MYDOMAINS_REQUEST,
  GET_MYDOMAINS_SUCCESS,
  GET_MYDOMAINS_ERROR,
  OPEN_RENEW_POPUP,
  CLOSE_RENEW_POPUP,
  RENEW_DOMAIN_REQUEST,
  RENEW_DOMAIN_SUCCESS
} from '../actions/mydomainsAction';
import {INIT, LOADING, SUCCESS} from '../utils/asyncStatusHelper';

const initialState = {
  myDomainsAsyncStatus: INIT,
  renewDomainAsyncStatus: INIT,
  domains: [],
  renewPopupOpen: false,
  renewPopupDomain: null,
};

export default function domainSearch(state = initialState, {type, payload}) {
  switch (type) {
    case GET_MYDOMAINS_REQUEST:
      return {
        ...state,
        ...payload,
        myDomainsAsyncStatus: LOADING,
      };
    case GET_MYDOMAINS_SUCCESS:
      return {
        ...state,
        domains: payload,
        myDomainsAsyncStatus: SUCCESS,
      };
    case OPEN_RENEW_POPUP:
      return {
        ...state,
        renewPopupOpen: true,
        renewPopupDetails: payload,
      };
    case CLOSE_RENEW_POPUP:
      return {
        ...state,
        ...payload,
        renewPopupOpen: false,
        renewPopupDomain: null,
      };
    case GET_MYDOMAINS_ERROR:
      return initialState;
    case RENEW_DOMAIN_REQUEST:
      return {
        ...state,
        renewDomainAsyncStatus: LOADING,
      };
    case RENEW_DOMAIN_SUCCESS:
      return {
        ...state,
        renewPopupOpen: false,
        renewPopupDomain: null,
        renewDomainAsyncStatus: INIT,
      };

    default:
      return state;
  }
}
