import axios from 'axios';
import {showLoading, hideLoading} from 'react-redux-loading-bar';
import {SESSION_SUCCESS} from './authAction';

export const GET_USER_PLAN_REQUEST = 'GET_USER_PLAN_REQUEST';
export const GET_USER_PLAN_SUCCESS = 'GET_USER_PLAN_SUCCESS';
export const GET_USER_PLAN_ERROR = 'GET_USER_PLAN_ERROR';

export const UPGRADE_PLAN_REQUEST = 'UPGRADE_PLAN_REQUEST';
export const UPGRADE_PLAN_SUCCESS = 'UPGRADE_PLAN_SUCCESS';
export const UPGRADE_PLAN_ERROR = 'UPGRADE_PLAN_ERROR';

export const DOWNGRADE_PLAN_REQUEST = 'DOWNGRADE_PLAN_REQUEST';
export const DOWNGRADE_PLAN_SUCCESS = 'DOWNGRADE_PLAN_SUCCESS';
export const DOWNGRADE_PLAN_ERROR = 'DOWNGRADE_PLAN_ERROR';

export const OPEN_DOWNGRADE_POPUP = 'OPEN_DOWNGRADE_POPUP';
export const CLOSE_DOWNGRADE_POPUP = 'CLOSE_DOWNGRADE_POPUP';

export const getUserPlan = () => async (dispatch) => {
  dispatch({type: GET_USER_PLAN_REQUEST});
  dispatch(showLoading());
  try {
    const {data} = await axios.get('/api/v2/user/membership');
    dispatch(hideLoading());
    dispatch({type: GET_USER_PLAN_SUCCESS, payload: data});
  } catch (error) {
    dispatch(hideLoading());
    dispatch({type: GET_USER_PLAN_ERROR, payload: error.data});
  }
};

export const upgradePlan = (subscriptionId) => async (dispatch) => {
  dispatch({type: UPGRADE_PLAN_REQUEST});
  dispatch(showLoading());
  try {
    const {data: {session}} = await axios.post('/api/v2/paypal/subscribe', {
      subscriptionId,
    });
    dispatch(hideLoading());
    dispatch({type: UPGRADE_PLAN_SUCCESS});
    dispatch({type: SESSION_SUCCESS, payload: session});
  } catch (error) {
    dispatch(hideLoading());
    dispatch({type: UPGRADE_PLAN_ERROR, payload: error.data});
  }
};

export const downgradePlan = () => async (dispatch) => {
  dispatch({type: DOWNGRADE_PLAN_REQUEST});
  dispatch(showLoading());
  try {
    const {data: {session}} = await axios.post(
      '/api/v2/user/membership/downgrade',
      {}
    );
    dispatch(hideLoading());
    dispatch({type: SESSION_SUCCESS, payload: session});
    // dispatch({type: DOWNGRADE_PLAN_SUCCESS });
  } catch (error) {
    dispatch(hideLoading());
    // dispatch({type: DOWNGRADE_PLAN_ERROR, payload: error.data});
  }
};

export const openDowngradePopup = () => (dispatch) => {
  dispatch({type: OPEN_DOWNGRADE_POPUP});
};

export const closeDowngradePopup = () => (dispatch) => {
  dispatch({type: CLOSE_DOWNGRADE_POPUP});
};
