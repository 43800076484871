import React from 'react';
import {useSelector} from 'react-redux';
import {Card, Row, Col, Divider} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import Payment from './payment';

const FreeEmpty = () => {
  const {
    // eslint-disable-next-line no-empty-pattern
    domainSearchReducer: {},
    session,
  } = useSelector(({domainSearchReducer, sessionReducer}) => ({
    domainSearchReducer,
    session: sessionReducer.session,
  }));

  const {
    plan: {
      isPro,
      limits: {domain: domainLimit},
    },
    usage: {allDomains: usageDomain=0},
  } = session;

  if (isPro) {
    return null;
  }
  
  // if (usageDomain < domainLimit) {
  //   return null;
  // }

  return (
    <>
      <div style={{marginTop: '30px', marginBottom: '25px'}}></div>
      {/* <div
        style={{
          padding: '20px',
          background: 'white',
          width: '900px',
          margin: '20px auto',
        }}
      >
        You've reached your account's limit of 3 domains. Please upgrade to PRO Membership to register more domains.
      </div> */}

      <Row>
        <Col span={7} offset={5} style={{background: '#ffffff'}}>
          <Card
            style={{
              border: '2px solid #fe4365',
              minHeight: '300px',
              background: '#fe4365',
              color: '#ffffff',
            }}
          >
            <p style={{marginBottom: '20px'}}>
              <span
                style={{
                  background: 'fd4264',
                  padding: '5px 10px',
                  border: '2px solid #ffffff',
                  borderRadius: '3px',
                  fontWeight: '600',
                }}
              >
                PRO Domain + Membership
              </span>
            </p>
            <p>
              <strong>Domain </strong>{' '}
              <strong
                style={{
                  borderBottom: '2px solid white',
                  padding: '4px 0px',
                }}
              >
                Never Expires
              </strong>
            </p>
            <p>
              Domain never expires untill you are in pro membership. No
              reactivation is necessory
            </p>
            <Divider>
              <PlusOutlined style={{color: '#ffffff'}} />
            </Divider>
            <p>
              Includes{' '}
              <span
                style={{
                  fontWeight: 700,
                  background: '#ffffff',
                  display: 'inline-block',
                  padding: '2px 5px',
                  color: '#fe4365',
                  borderRadius: '3px',
                }}
              >
                25 PRO Domains
              </span>
              <p style={{marginTop: '15px'}}>
                You can also delete and reuse the unwanted domains
              </p>
            </p>
          </Card>
        </Col>
        <Col span={7}>
          <Card
            style={{
              minHeight: '200px',
              background: '#ffffff',
              color: '#4b4b4b',
            }}
          >
            <div>
              <div>
                Upgrade to PRO Plan for{'  '}
                <strong style={{fontSize: '18px', color: '#fe4365'}}>
                  $4 / Month
                </strong>
              </div>
              <div>Cancel Anytime</div>
            </div>
            <Payment></Payment>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default FreeEmpty;
